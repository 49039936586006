
export interface Movie
{
    id: string;
    instant?: number;

    title: string;
    description: string;
    link: string;
    thumbnailLink: string;
    tagline: string;
    message: string;

    isDeleted: boolean;
    isWatched: boolean;
}

export function newMovie(): Movie
{
    return {
        id: Math.random().toFixed(20),
        title: "",
        description: "",
        link: "",
        thumbnailLink: "",
        tagline: "",
        message: "",
        isDeleted: false,
        isWatched: false,
    };
}

export function movieScheduleSort(a: Movie, b: Movie)
{
    if (!a.instant || !b.instant)
    {
        return 0;
    }
    
    return a.instant - b.instant;
}