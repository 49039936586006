import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Movie, newMovie } from "./Movie";
import { update } from "../Logic/Identify";
import { s3Update } from "./S3Store";

export interface IQueueState 
{
    queue: Movie[];
    selected: string;
    sm: Movie;
}

export default createSlice({
    name: "Queue",
    initialState: {
        queue: [] as Movie[],
        selected: "",
        sm: newMovie(),
    },
    reducers: (create) => ({
        updateMovie: create.reducer<Movie>((state, payload) =>
        {
            update(state.queue, payload.payload);
        }),
        setSelected: create.reducer<string>((state, payload) =>
        {
            state.selected = payload.payload;
        }),
        deleteSelected: create.reducer((state) =>
        {
            state.sm.isDeleted = true;
            update(state.queue, state.sm);
        }),
        watchSelected: create.reducer((state) =>
        {
            state.sm.isWatched = true;
            update(state.queue, state.sm);
        }),
        setsm: create.reducer<Movie>((state, payload) =>
        {
            state.sm = payload.payload;
        }),
        updateSelectedProp: create.reducer<{p: keyof Movie, v: string | number}>((state, payload) =>
        {
            (state.sm as any)[payload.payload.p] = payload.payload.v;
        }),
        replaceState: create.reducer<Movie[]>((state, payload) => {
            state.queue.splice(0, state.queue.length);
            state.queue.push(...payload.payload);
        }),
    })
});

export async function updateMovieRemote(state: IQueueState, payload: Movie)
{
    let stateCopy = JSON.parse(JSON.stringify(state));
    await s3Update(stateCopy.queue, payload);
    return stateCopy.queue;
}
export async function watchSelectedRemote(state: IQueueState)
{
    state.sm.isWatched = true;
    await s3Update(state.queue, state.sm);
    return state;
}
export async function deleteSelectedRemote(state: IQueueState)
{
    state.sm.isDeleted = true;
    await s3Update(state.queue, state.sm);
    return state;
}