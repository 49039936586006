
import "./StyleSheet.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap";

import * as Bootstrap from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import AppStore, { IAppState } from "./Model/AppStore";
import QueueSlice, { updateMovieRemote } from "./Model/QueueSlice";
import { Movie, movieScheduleSort, newMovie } from "./Model/Movie";
import { getFile } from "./Model/S3Store";


export interface ISettings
{
    discordUrl: string,
}

let settings: ISettings | null = null;
if (window.location.hash)
{
    settings = JSON.parse(atob(window.location.hash.slice(1))) as ISettings;
}

function App()
{
    const dispatch = useDispatch();
    const movieList = useSelector<IAppState, Movie[]>(state => state.queue.queue);


    const scheduledMovies = movieList
        .filter(movie => !movie.isDeleted)
        .filter(movie => !movie.isWatched)
        .filter(movie => movie.instant)
        .sort(movieScheduleSort);

    const unscheduledMovies = movieList
        .filter(movie => !movie.isDeleted)
        .filter(movie => !movie.isWatched)
        .filter(movie => !movie.instant);

    const sm = useSelector<IAppState, Movie>(state => state.queue.sm);

    return (
        <div className="App">
            <Bootstrap.Container>
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <h3>
                            Schedule
                        </h3>
                        <Bootstrap.ListGroup>
                            {
                                scheduledMovies.map(movie =>
                                    (
                                        <Bootstrap.ListGroupItem
                                            action
                                            onClick={() => {
                                                dispatch(QueueSlice.actions.setsm(movie));
                                            }}
                                            active={movie.id === sm.id}
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div>
                                                {movie.title}
                                            </div>
                                            {movie.instant ? 
                                                <Bootstrap.Badge
                                                    bg="primary"
                                                >
                                                    {new Date(movie.instant).toLocaleString(undefined, {
                                                        day: "2-digit",
                                                        month: "short",
                                                    })}
                                                </Bootstrap.Badge>
                                                :
                                                null}
                                        </Bootstrap.ListGroupItem>
                                    )
                                )
                            }
                            {/* <Bootstrap.Button
                                onClick={async () => {
                                    const response = await fetch("https://sq-api.xv.vc/myUserId");
                                    console.log(response.text());
                                    
                                    // const newWindow = window.open("https://sq-api.xv.vc/connect/discord", "_blank", "popup");
                                    
                                    // window.addEventListener("message", (ev) => {
                                    //     if (ev.data === "X") 
                                    //     {
                                    //         newWindow?.close();
                                    //     }
                                    // })
                                    
                                }}
                            >
                                Login
                            </Bootstrap.Button> */}
                        </Bootstrap.ListGroup>
                    </Bootstrap.Col>
                    <Bootstrap.Col>
                        <h3>
                            Queue
                        </h3>
                        <Bootstrap.ListGroup>
                            {
                                unscheduledMovies.map(movie =>
                                    (
                                        <Bootstrap.ListGroupItem
                                            action
                                            onClick={() => {
                                                dispatch(QueueSlice.actions.setsm(movie));
                                            }}
                                            active={movie.id === sm.id}
                                        >
                                            {movie.title}
                                        </Bootstrap.ListGroupItem>
                                    )
                                )
                            }
                        </Bootstrap.ListGroup>
                    </Bootstrap.Col>
                    <Bootstrap.Col>
                        <Bootstrap.Button
                            className="w-100"
                            onClick={() => {
                                dispatch(QueueSlice.actions.setsm(newMovie()))
                            }}
                            variant={movieList.map(m => m.id).includes(sm.id) ? "outline-primary" : "primary"}
                        >
                            New
                        </Bootstrap.Button>
                        Selection
                        <EditMovie
                            onSave={async () => {
                                //dispatch(QueueSlice.actions.updateMovie(sm));
                                let newQueue = await updateMovieRemote(AppStore.getState().queue, sm);
                                dispatch(QueueSlice.actions.replaceState(newQueue));
                            }}
                        />


                    </Bootstrap.Col>
                </Bootstrap.Row>
            </Bootstrap.Container>

        </div>
    );
}

interface IEditMovieProps
{
    onSave: () => void;
}
function EditMovie(props: IEditMovieProps)
{
    const { onSave } = props;
    const d = useDispatch();
    const title = useSelector<IAppState, string>(state => state.queue.sm.title);
    const description = useSelector<IAppState, string | undefined>(state => state.queue.sm.description);
    const tagline = useSelector<IAppState, string | undefined>(state => state.queue.sm.tagline);
    const link = useSelector<IAppState, string | undefined>(state => state.queue.sm.link);
    const thumbnailLink = useSelector<IAppState, string | undefined>(state => state.queue.sm.thumbnailLink);
    const instant = useSelector<IAppState, number | undefined>(state => state.queue.sm.instant);
    
    return (
        <Bootstrap.Form
            onSubmit={(ev) => {
                ev.preventDefault();
                onSave();
            }}
            style={{
                position: "sticky",
                top: "0",
            }}
        >
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Title
                </Bootstrap.FormLabel>
                <Bootstrap.InputGroup>
                    <Bootstrap.FormControl
                        type="input"
                        value={title}
                        onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"title", v: ev.currentTarget.value}))}
                    />
                    <Bootstrap.Button
                        onClick={() => {
                            const searchUrl = "https://www.themoviedb.org/search/movie?language=en-US&query=";
                            window.open(searchUrl + title.replace(" ", "+"), "_blank")
                        }}
                    >
                        <i className="bi bi-search"></i>
                    </Bootstrap.Button>
                </Bootstrap.InputGroup>
            </Bootstrap.FormGroup>
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Description
                </Bootstrap.FormLabel>
                <Bootstrap.FormControl
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"description", v: ev.currentTarget.value}))}
                />
            </Bootstrap.FormGroup>
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Tag Line
                </Bootstrap.FormLabel>
                <Bootstrap.FormControl
                    type="input"
                    value={tagline}
                    onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"tagline", v: ev.currentTarget.value}))}
                />
            </Bootstrap.FormGroup>
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Link
                </Bootstrap.FormLabel>
                <Bootstrap.InputGroup>
                    <Bootstrap.FormControl
                        type="input"
                        value={link}
                        onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"link", v: ev.currentTarget.value}))}
                    />
                    <Bootstrap.Button
                        onClick={() => {
                            window.open(link, "_blank")
                        }}
                    >
                        <i className="bi bi-link-45deg"></i>
                    </Bootstrap.Button>
                </Bootstrap.InputGroup>
            </Bootstrap.FormGroup>
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Thumbnail Link
                </Bootstrap.FormLabel>
                <Bootstrap.FormControl
                    type="input"
                    value={thumbnailLink}
                    onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"thumbnailLink", v: ev.currentTarget.value}))}
                />
            </Bootstrap.FormGroup>
            
            <Bootstrap.FormGroup
                className="mb-3"
            >
                <Bootstrap.FormLabel>
                    Instant
                </Bootstrap.FormLabel>
                <Bootstrap.FormControl
                    type="datetime-local"
                    value={instant ? getInstantString(instant) : "2100-01-01:T00:00"}
                    onChange={(ev) => d(QueueSlice.actions.updateSelectedProp({p:"instant", v: (new Date(ev.currentTarget.value)).getTime()}))}
                />
            </Bootstrap.FormGroup>

            <Bootstrap.ButtonGroup
                className="w-100"
            >
                <Bootstrap.Button
                    type="submit"
                    variant="outline-primary"
                >
                    Save
                </Bootstrap.Button>
                <Bootstrap.Button
                    variant="outline-secondary"
                    onClick={() => {
                        
                        // Ratcon
                        const url = settings?.discordUrl ?? "";
                        
                        // Test
                        //const url = 'https://discord.com/api/webhooks/915009026197184532/_DgTq1OaZaeS9IaQxkCTWOjNc5DB60DtT0h2nw0FeTYwucJ_CV9OUfPgoonOmTynMkyQ';
                        
                        let whenMessage = null;
                        if (instant)
                        {
                            const discordInstant = Math.floor((new Date(instant).getTime())/1000);

                            whenMessage = `Scheduled for <t:${discordInstant}>`;

                            if (isToday(instant))
                            {
                                whenMessage = `Tonight at <t:${discordInstant}:t>.`
                            }
                            else if (isTomorrow(instant))
                            {
                                whenMessage = `Tomorrow at <t:${discordInstant}:t>.`
                            }
                        }
                        
                        const postData = JSON.stringify({
                            username: "Let's Watch",
                            embeds: [
                                {
                                    title: title,
                                    description: description,
                                    fields: [
                                        {
                                            name: "\u200B",
                                            value: whenMessage,
                                            inline: false,
                                        },
                                    ],
                                    url: link,
                                    thumbnail: {
                                        url: thumbnailLink,
                                    },
                                    footer: {
                                        text: tagline,
                                    },
                                    //color: 0xef1823,
                                },
                            ],
                        });
                        const request = new Request(url, {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: postData,
                        })
                        fetch(request);
                    }}
                >
                    <i className="bi bi-discord" ></i>
                </Bootstrap.Button>
                <Bootstrap.Button
                    variant="outline-success"
                    onClick={() => d(QueueSlice.actions.watchSelected())}
                >
                    Watched
                </Bootstrap.Button>
                <Bootstrap.Button
                    variant="outline-danger"
                    onClick={() => d(QueueSlice.actions.deleteSelected())}
                >
                    Delete
                </Bootstrap.Button>
            </Bootstrap.ButtonGroup>

        </Bootstrap.Form>
    );
}

export default App;

function getInstantString(instant: number)
{
    const date = new Date(instant);
    const year = String(date.getFullYear()).padStart(4, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hour}:${minute}`;
}

function isToday(instant: number)
{
    const date = new Date(instant);
    const today = new Date();

    if (today.getFullYear() !== date.getFullYear())
    {
        return false;
    }

    if (today.getMonth() !== date.getMonth())
    {
        return false;
    }

    if (today.getDate() !== date.getDate())
    {
        return false;
    }

    return true;
}

function isTomorrow(instant: number)
{
    const date = new Date(instant);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1);

    if (tomorrow.getFullYear() !== date.getFullYear())
    {
        return false;
    }

    if (tomorrow.getMonth() !== date.getMonth())
    {
        return false;
    }

    if (tomorrow.getDate() !== date.getDate())
    {
        return false;
    }

    return true;
}

AppStore.subscribe(() => {
    //localStorage.setItem("mq", JSON.stringify(AppStore.getState()));
});
// const storredQueue = localStorage.getItem("mq");
// if (storredQueue)
// {
//     const q = JSON.parse(storredQueue) as IAppState;
//     for (const movie of q.queue.queue)
//     {
//         AppStore.dispatch(QueueSlice.actions.updateMovie(movie));
//     }
// }
(async function () {
    const storredQueue = await getFile();
    if (storredQueue)
    {
        const q = JSON.parse(storredQueue) as Movie[];
        for (const movie of q)
        {
            AppStore.dispatch(QueueSlice.actions.updateMovie(movie));
        }
    }
})()