import { configureStore } from "@reduxjs/toolkit";
import EditMovieSlice from "./EditMovieSlice";
import { Movie } from "./Movie";
import QueueSlice, { IQueueState } from "./QueueSlice"

export interface IAppState
{
    queue: IQueueState;
    editMovie: Movie;
}

export default configureStore<IAppState>({
    reducer: {
        queue: QueueSlice.reducer,
        editMovie: EditMovieSlice.reducer,
    },
});

