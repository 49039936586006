export type Identifier = string;

export interface IIdDb<T extends IIdentifiable>
{
    update<T>(identifiable: T): void;
}

export interface IIdentifiable
{
    id: Identifier;
}

function identifyIndex(identifiables: IIdentifiable[], id: Identifier): number
{
    return identifiables.findIndex(identifiable => id === identifiable.id);
}

export function identify<T extends IIdentifiable>(identifiables: T[], id: Identifier): T | undefined
{
    const index = identifyIndex(identifiables, id);
    if (index >= 0)
    {
        return identifiables[index];
    }
    return undefined;
}

export function update(identifiables: IIdentifiable[], identifiable: IIdentifiable)
{
    const index = identifyIndex(identifiables, identifiable.id);
    if (index >= 0)
    {
        identifiables[index] = identifiable;
    }
    else
    {
        identifiables.push(identifiable);
    }
}

export function deleteFromArray(identifiables: IIdentifiable[], id: Identifier)
{
    const index = identifyIndex(identifiables, id);
    if (index >= 0)
    {
        identifiables.splice(index, 1);
    }
}