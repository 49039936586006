import { createSlice } from "@reduxjs/toolkit";
import { Movie, newMovie } from "./Movie";
import { update } from "../Logic/Identify";

export default createSlice({
    name: "EditMovie",
    initialState: newMovie(),
    reducers: {
        setSelected: (state, payload) => 
        {
            state = payload.payload;
        },
        setTitle: (state, payload) =>
        {
            state.title = payload.payload.title;
        },
    }
});