import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { IIdentifiable, update } from "../Logic/Identify";

export interface ISettings
{
    b2Key: string,
    b2Secret: string,
    b2Bucket: string,
    b2File: string,
    discordUrl: string,
}

let settings: ISettings | null = null;
if (window.location.hash)
{
    settings = JSON.parse(atob(window.location.hash.slice(1))) as ISettings;
}

const s3 = new S3Client({
    endpoint: "https://s3.us-east-005.backblazeb2.com",
    region: "us-west-005",
    credentials: {
        accessKeyId: settings!.b2Key,
        secretAccessKey: settings!.b2Secret,
    }
});

export async function getFile(): Promise<string>
{
    if (!settings) return "[]";

    try {
        return await (await s3.send(new GetObjectCommand({
            Bucket: settings.b2Bucket,
            Key: settings.b2File,
        }))).Body?.transformToString() ?? "[]";
    }
    catch {
        return "[]";
    }
}

export async function setFile(body: string)
{
    if (!settings) return;

    await (await s3.send(new PutObjectCommand({
        Bucket: settings.b2Bucket,
        Key: settings.b2File,
        Body: body,
    })))
}

export async function s3Update(localIdentifiables: IIdentifiable[], identifiable: IIdentifiable)
{
    const remoteFile = await getFile();
    const localFile = JSON.stringify(localIdentifiables);

    const remoteIdentifiables = JSON.parse(remoteFile) as IIdentifiable[];

    update(remoteIdentifiables, identifiable);

    await setFile(JSON.stringify(remoteIdentifiables));

    localIdentifiables.splice(0, localIdentifiables.length)
    localIdentifiables.push(...remoteIdentifiables);
}